import React, { useEffect, useState } from 'react'

export const useMonetization = () => {
  const [isMonetized, setIsMonetized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!document.monetization) {
      // This means this user doesn't have monetization capabilities
      // i.e. they don't have the Coil extension installed on their browser
      setIsLoading(false)
      setIsMonetized(false)
      return
    }

    // Note: A user could have monetization capabilities (i.e. installed Coil)
    // but that doesn't mean they've actually signed up for an account!
    const { state } = document.monetization

    // If the initial state is 'stopped', we can assume the user isn't
    // going to pay, and so we can stop loading
    if (state === 'stopped') {
      setIsLoading(false)
      setIsMonetized(false)
    }

    // We add a listener to wait for the user to start paying
    document.monetization.addEventListener('monetizationstart', () => {
      setIsMonetized(true)
      setIsLoading(false)
    })
  }, [])

  return { isMonetized, isLoading }
}

export const MonetizedContent = ({ title, children }) => {
  const { isMonetized, isLoading } = useMonetization()

  if (isLoading) {
    return <div>Loading...</div>
  } else if (isMonetized) {
    return (
      <>
      <div className="blog-list-item tile is-child box has-background-white-ter">
        <h2>{title}</h2>
        {children}
      </div>
      <br/>
      </>
    )
  } else {
    return (
      <div className="box has-background-white-ter">
        <em>
          Extra content for <a target="_blank" rel="noopener noreferrer" href="https://coil.com">Coil members</a>:
        </em>{' '}
        <strong>{title}</strong>
      </div>
    )
  }
}
