import React from 'react'
import PropTypes from 'prop-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { MonetizedContent } from '../components/Monetized'

export const HTMLContent = ({ content, className }) => (
  <div className={className}>
    <MDXProvider components={{ MonetizedContent }}>
      <MDXRenderer>{content}</MDXRenderer>
    </MDXProvider>
  </div>
)

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
