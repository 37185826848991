import React from 'react'

// Url
// Gatsby does not create markdown out of frontmatter
// This component used to create url from text in frontmatter

const Url = ({ url, alt, text }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={url} alt={alt}>
      {text}
    </a>
  )
}

export default Url
