import React from 'react'

const Contact = () => (
  <div className="footer-primary">
    <section className="section">
      <div className="container">&nbsp;</div>
    </section>
  </div>
)

export default Contact
