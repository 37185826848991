import React from "react";
import Contact from "../components/Contact";
import BottomNav from "../components/BottomNav";
import TsFooterLogo from "../../static/img/TS-logo-footer.png";
import Facebook from "../../static/img/social/f_logo_RGB-Black_58.png";
import Instagram from "../../static/img/social/i_glyph-logo_May2016.png";
import Pinterest from "../../static/img/social/p_badgeRGB-000000.svg";
// children will be used to pass the license
// removing temporarily for initial release

const Footer = ({ children }) => {
  return (
    <>
      <BottomNav />
      <div>
        <Contact />
      </div>
      <div>
        <footer className="footer">
          <div className="columns is-multiline is-centered">
            <div className="column is-12 title has-text-centered">
              <img alt="Teddy Story Logo" src={TsFooterLogo} />
            </div>
            <div className="column is-12 title has-text-centered">
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="instagram"
                href="https://www.facebook.com"
              >
                <img
                  className="fas fa-lg"
                  alt="Facebook"
                  src={Facebook}
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
              &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="instagram"
                href="https://www.instagram.com"
              >
                <img
                  className="fas fa-lg"
                  alt="Instagram"
                  src={Instagram}
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
              &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="instagram"
                href="https://www.pinterest.com"
              >
                <img
                  className="fas fa-lg"
                  alt="Pinterest"
                  src={Pinterest}
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
            </div>
            <div className="column has-text-centered is-size-7">
              <p>
                &copy; 2021{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="unomia"
                  href="https://unomia.com"
                >
                  Unomia Pty Ltd{" "}
                </a>
                <br />
                ABN 48 606 086 542
              </p>
            </div>
            <div className="column has-text-centered is-size-7">
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.iubenda.com/privacy-policy/23811268"
                >
                  Privacy |
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.iubenda.com/privacy-policy/23811268/cookie-policy"
                >
                  {" "}
                  Cookies |
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.iubenda.com/terms-and-conditions/23811268"
                >
                  {" "}
                  Terms{" "}
                </a>
                <br />
                <a
                  rel="contact-us"
                  href="mailto:teddy@teddy-story.com?subject=Website enquiry"
                >
                  {" "}
                  Contact
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
