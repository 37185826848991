import React from "react";
import { Link } from "gatsby";

const BottomNav = () => (
  <div className="footer-cream">
    <section className="section">
    </section>
    <div className="footer-light">
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <div className="columns is-centered">
              <div className="column is-one-quarter">
                <Link className="button has-text-link" to="/blog/">
                  Behind the scenes
                </Link>
              </div>
              <div className="column is-one-quarter">
                <a
                  className="button has-text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://trello.com/b/4Yc3usJc/teddystory-roadmap"
                >
                  What&apos;s coming up?
                </a>
              </div>
              <div className="column is-one-quarter">
                <Link className="button has-text-link" to="/gftw/">
                  Grant for the Web
                </Link>
              </div>
              <div className="column is-one-quarter">
                <Link className="button has-text-link" to="/stories/">
                  Read the stories
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default BottomNav;
